<template>
  <Dialog :title="null" variant="narrow">
    <div class="text-center pt-4 flex flex-col">
      <ExclamationTriangleIcon class="size-9 text-gray-500 mb-2 mx-auto" />
      <div class="text-xl font-bold mb-4">Error</div>
      <div v-if="props.error.detail && Array.isArray(props.error.detail)">
        <div v-for="detail in props.error.detail" :key="detail">
          <div v-if="typeof detail === 'object'">
            <div v-for="(value, key) in detail" :key="key">
              <div class="font-bold">{{ key }}</div>
              <div>{{ value }}</div>
            </div>
          </div>
          <div v-else>{{ detail }}</div>
        </div>
      </div>
      <div v-else class="text-lg whitespace-pre-wrap">
        {{
          props.error.detail ||
          "Buttondown ran into an error.\n(Sorry, that's not helpful, but our team has been alerted.)"
        }}
      </div>

      <div v-if="shouldShowUpgradeButton" class="mt-4">
        <ActionButton
          text="Upgrade"
          :to="{ name: '/settings/billing/pricing' }"
          variant="primary"
        />
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import ActionButton from "@/components/Utilities/ActionButton.vue";
import Dialog from "@/design_system/Dialog/Dialog.vue";
import ExclamationTriangleIcon from "@/icons/heroicons/exclamation-triangle-solid.svg";

const props = defineProps<{
  error: OpenAPI["schemas"]["ErrorMessage"];
}>();

const shouldShowUpgradeButton = computed(() => {
  return (
    props.error.detail?.includes("please upgrade your account") ||
    props.error.detail?.includes("upgrade your plan")
  );
});
</script>
