<template>
  <ComboboxItem
    :value="generateAction(item)"
    class="-mx-2 flex scroll-m-3 gap-3 rounded-md px-1.5 py-1 text-gray-700 select-none data-[highlighted]:bg-gray-100 data-[highlighted]:text-gray-900"
  >
    <div
      :class="[
        `flex shrink-0 items-center justify-center self-start rounded p-1`,
        item.type === 'page' || item.type === 'special_action'
          ? `bg-gray-200`
          : `bg-blue-500 text-white`,
      ]"
    >
      <component
        :is="item.brandIcon"
        v-if="item.type === 'page' && item.brandIcon"
        class="h-4 w-4"
      />
      <component :is="icon" v-else-if="icon" class="h-4 w-4" />
    </div>

    <span class="my-0.5 grow text-sm font-medium">
      {{ item.text || "No subject" }}
    </span>

    <span class="mt-0.5 text-right text-[13px] text-gray-500 empty:hidden">
      <Datetime
        v-if="item.type === 'email'"
        :value="item.obj.publish_date || item.obj.creation_date"
      />
      <Datetime
        v-else-if="
          item.type === 'historical_email' ||
          item.type === 'subscriber' ||
          item.type === 'automation' ||
          item.type === 'comment'
        "
        :value="item.obj.creation_date"
      />
    </span>
  </ComboboxItem>
</template>

<script setup lang="ts">
import { ComboboxItem } from "radix-vue";
import { type Component, computed } from "vue";

import Datetime from "@/design_system/Value/Datetime.vue";
import ArrowRightStartOnRectangleIcon from "@/icons/heroicons/arrow-right-start-on-rectangle-micro.svg";
import ArrowTopRightOnSquareIcon from "@/icons/heroicons/arrow-top-right-on-square-micro.svg";
import BookOpenIcon from "@/icons/heroicons/book-open-micro.svg";
import ChartBarIcon from "@/icons/heroicons/chart-bar-micro.svg";
import ChatBubbleLeftRightIcon from "@/icons/heroicons/chat-bubble-left-right-micro.svg";
import ClockIcon from "@/icons/heroicons/clock-micro.svg";
import CodeBracketIcon from "@/icons/heroicons/code-bracket-micro.svg";
import EnvelopeIcon from "@/icons/heroicons/envelope-micro.svg";
import QuestionMarkCircleIcon from "@/icons/heroicons/question-mark-circle-micro.svg";
import SparklesIcon from "@/icons/heroicons/sparkles-micro.svg";
import TagIcon from "@/icons/heroicons/tag-micro.svg";
import UserIcon from "@/icons/heroicons/user-micro.svg";
import { generateAction } from "@/lib/search";
import { SEARCH_CONTEXT } from "@/search/routes";
import { SearchResult } from "@/types/search_result";

const typeIconMapping: Record<SearchResult["type"], Component> = {
  automation: SparklesIcon,
  comment: ChatBubbleLeftRightIcon,
  documentation: BookOpenIcon,
  email: EnvelopeIcon,
  invitation: UserIcon,
  newsletter: QuestionMarkCircleIcon,
  page: ArrowTopRightOnSquareIcon,
  request: CodeBracketIcon,
  subscriber: UserIcon,
  survey: ChartBarIcon,
  tag: TagIcon,
  webhook: CodeBracketIcon,
  historical_email: ClockIcon,
  message: ChatBubbleLeftRightIcon,
  special_action: ArrowRightStartOnRectangleIcon,
};

const props = defineProps<{
  item: SearchResult;
}>();

const icon = computed(() => {
  const item = props.item;

  if (item.type == "page" || item.type == "special_action") {
    // @ts-ignore
    const page = SEARCH_CONTEXT[item.obj.name];
    if (page) {
      return page.icon;
    }
  }

  return typeIconMapping[item.type];
});
</script>
