<template>
  <div class="flex-1 flex flex-col">
    <div
      v-bind="$attrs"
      class="border-box flex min-h-0 flex-1 flex-col border border-y-0 border-l-0 border-r-0 border-solid border-gray-300 bg-gray-50 lg:w-[200px] lg:border-r"
    >
      <div
        class="flex items-center gap-2 p-2 border-b justify-between border-gray-300"
      >
        <Header :newsletter="newsletter" :permissions="account.permissions" />

        <button
          type="button"
          aria-label="Toggle sidebar"
          class="grid h-[1.875rem] w-[1.875rem] shrink-0 place-items-center rounded-md hover:bg-gray-200"
          @click="toggleSidebar"
        >
          <Bars3Icon class="h-4 w-4" />
        </button>
      </div>

      <div class="flex flex-1 flex-col space-y-2 overflow-y-auto px-2 py-4">
        <SearchButton @click="emit('triggerSearch')" />
        <template v-for="item in activeNavigation" :key="item.icon">
          <NavigationItem
            v-if="item.route && item.title && item.icon"
            :route="item.route"
            :icon="item.icon"
            :title="item.title"
          />
        </template>
        <NavigationItem
          :route="{
            name: '/settings/basics',
          }"
          :icon="CogIcon"
          title="Settings"
        />
        <div
          v-if="showingSettings"
          :class="{
            'bg-gray-200/50 -mx-2 px-2 transition-all border-t border-t-gray-300 border-b border-b-gray-300': true,
          }"
        >
          <Sidebar />
        </div>
        <div class="flex-1" />
        <NavigationItem
          v-if="
            account.billing_plan === 'free' &&
            account.billing_type === 'V2' &&
            newsletter.owning_account_billing_plan === 'free'
          "
          :route="{
            name: '/settings/billing/pricing',
          }"
          :icon="BoltIcon"
          title="Upgrade"
          emphasize
        />
        <NavigationItem
          url="https://docs.buttondown.com"
          :icon="BookOpenIcon"
          title="Documentation"
        />
        <NavigationItem
          v-if="!isDemo"
          :icon="ArrowRightStartOnRectangleIcon"
          title="Log out"
          @click="showLogout = true"
        />
      </div>
    </div>

    <Dialog v-if="showLogout" title="Log out?" @close="showLogout = false">
      <template #actions>
        <ActionButton
          text="Log out"
          icon="arrow-right-start-on-rectangle"
          variant="primary"
          @click="logout"
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useRouter } from "vue-router/auto";

import ActionButton from "@/components/Utilities/ActionButton.vue";
import Dialog from "@/design_system/Dialog/Dialog.vue";
import ArrowRightStartOnRectangleIcon from "@/icons/heroicons/arrow-right-start-on-rectangle-micro.svg";
import Bars3Icon from "@/icons/heroicons/bars-3-outline.svg";
import BoltIcon from "@/icons/heroicons/bolt-micro.svg";
import BookOpenIcon from "@/icons/heroicons/book-open-micro.svg";
import CogIcon from "@/icons/heroicons/cog-6-tooth-micro.svg";
import { showToast } from "@/lib/toasts";
import Sidebar from "@/screens/Settings/Sidebar.vue";
import { useStore } from "@/store/stores/scaffolding";
import { Account } from "@/types/account";
import { Newsletter } from "@/types/newsletter";

import Header from "./Header/Component.vue";
import { determineActiveNavigation } from "./lib";
import NavigationItem from "./NavigationItem.vue";
import SearchButton from "./SearchButton.vue";

const props = defineProps<{
  account: Account;
  newsletter: Newsletter;
}>();

const emit = defineEmits<{
  triggerSearch: [];
}>();

const store = useStore();
const router = useRouter();
const toggleSidebar = store.toggleSidebar;
const showingSettings = computed(() =>
  router.currentRoute.value.path.startsWith("/settings")
);
const currentPermissions = computed(() => store.currentPermissions);
const isDemo = computed(() => store.isDemo);

const showLogout = ref(false);

const activeNavigation = computed(() => {
  return determineActiveNavigation(currentPermissions.value, props.newsletter);
});

const logout = async () => {
  await store.logout();
  location.href = "/login";

  showToast({
    type: "success",
    title: `Logged out successfully`,
    message: `Have a great day!`,
  });
};
</script>
