<template>
  <Menu.Root>
    <Menu.Trigger>
      <button
        type="button"
        class="font-bold text-center flex space-x-2 px-3 items-center text-sm overflow-hidden hover:bg-gray-200 py-2 rounded-xl cursor-pointer"
      >
        <NewsletterIcon :newsletter="props.newsletter" />
        <div
          class="whitespace-nowrap overflow-x-clip overflow-ellipsis flex-1 truncate text-left"
        >
          {{ props.newsletter.name || props.newsletter.username }}
        </div>
      </button>
    </Menu.Trigger>

    <Menu.Container>
      <Draggable v-model="sortedNewsletters">
        <Menu.Item
          v-for="(item, index) in sortedNewsletters"
          :key="item.id"
          :index="index"
          :label="item.name || item.username"
          :active="item.id === props.newsletter.id"
          :class="{
            'opacity-50': item.status === 'dormant',
          }"
          @click="switchNewsletter(item)"
        >
          <template #icon>
            <NewsletterIcon :newsletter="item" />
          </template>
        </Menu.Item>
      </Draggable>

      <Menu.RouteItem
        icon="plus"
        label="Add Newsletter"
        :to="{ name: '/settings/danger-zone/add-newsletter' }"
      />
    </Menu.Container>
  </Menu.Root>
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";

import Menu from "@/design_system/Menu";
import Draggable from "@/lib/pragmatic-dnd/Draggable.vue";
import { LocalStorageKey } from "@/store/local_storage";
import { useLocalStorageBackedRef } from "@/store/stores/base";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";
import { Permission } from "@/types/account";
import { Newsletter } from "@/types/newsletter";

import NewsletterIcon from "./NewsletterIcon.vue";

const store = useScaffoldingStore();

const props = defineProps({
  newsletter: {
    type: Object as PropType<Newsletter>,
    required: true,
  },
  permissions: {
    type: Array as PropType<Permission[]>,
    required: true,
  },
});

const sortedIds = useLocalStorageBackedRef<string[]>(
  LocalStorageKey.NEWSLETTERS_ORDER,
  [],
  (ordering) => JSON.stringify(ordering),
  (raw) => JSON.parse(raw || "[]")
);

const sortedNewsletters = computed({
  get() {
    const newsletters = props.permissions.map((p) => p.newsletter);

    const map = new Map(sortedIds.value.map((id, index) => [id, index]));
    const boundary = map.size;

    // Mutate the array as we've already cloned it from mapping
    newsletters.sort((a, b) => {
      return (map.get(a.id) ?? boundary) - (map.get(b.id) ?? boundary);
    });

    // Sort dormant newsletters to the bottom
    newsletters.sort((a, b) => {
      return a.status === "dormant" ? 1 : b.status === "dormant" ? -1 : 0;
    });

    return newsletters;
  },
  set(newsletters) {
    sortedIds.value = newsletters.map((n) => n.id);
  },
});

const switchNewsletter = store.switchNewsletter;
</script>
